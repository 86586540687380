import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/Layout"
import ProjectBox from "../components/ProjectBox"

import AYCMImage from "../images/projects/boxes/aycm.jpg"
import ErsteImage from "../images/projects/boxes/erste.jpg"
import HallidaiImage from "../images/projects/boxes/hallid.ai.png"
import PulzusImage from "../images/projects/boxes/pulzus.png"
import WorknImage from "../images/projects/boxes/workn.jpg"

const ProjectsPage = () => (
  <Layout>
    <div
      css={theme => css`
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

        flex-direction: column;

        @media (min-width: 1900px) {
          margin: 0 300px;
        }

        @media (min-width: 1700px) and (max-width: 1899px) {
          margin: 0 200px;
        }

        @media (min-width: 1500px) and (max-width: 1699px) {
          margin: 0 100px;
        }

        @media (max-width: 1200px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 1000px) {
          margin: ${theme.constants.smallpageMargin};
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }

        @media (max-width: 600px) {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          grid-column-gap: 20px;
          grid-row-gap: 50px;
          margin: 0 10px;
        }

        @media (max-width: 360px) {
          margin: ${theme.constants.smallpageMargin};
        }
      `}
    >
      <ProjectBox
        image={AYCMImage}
        title="AYCM – All You Can Move"
        description="The AYCM SportPass is a digital sports pass for almost all sports facilities in Hungary."
        to="aycm"
      />
      <ProjectBox
        image={WorknImage}
        title="WorkN"
        description="The labour market has never been so organized and optimized. Welcome to the third industrial revolution."
        to="workn"
      />
      <ProjectBox
        image={ErsteImage}
        title="Official application of Erste Liga"
        description="We developed the official application of the Erste Liga, which keeps us up-to-date with the status of the ice hockey tournament and players."
        to="erste"
      />
      <ProjectBox
        image={PulzusImage}
        title="Pulzus"
        description="Change the world with your opinion. Tell us what you think!"
        to="pulzus"
        outOfOrder
      />
      <ProjectBox
        image={HallidaiImage}
        title="Fridai"
        description="The virtual gaming assistant"
        to="fridai"
      />
    </div>
  </Layout>
)

export default ProjectsPage
