import React, { useState } from "react"
import { css } from "@emotion/core"
import { LinkButton, Image, Link } from "./common"

const ProjectBox = ({ image, title, description, to }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="project"
      css={theme => css`
        margin: 30px;
        text-align: left;

        display: flex;
        flex-direction: column;

        @media (max-width: 600px) {
          margin: 0;
        }

        &:hover {
          img:first-of-type {
            opacity: 1;
            filter: grayscale(0%);
            box-shadow: initial;
          }

          a:first-of-type {
            color: ${theme.colors.darkBlue};
          }

          div:first-of-type {
            color: ${theme.colors.darkBlue};
          }
        }
      `}
    >
      <Link
        to={`/projects/${to}`}
        css={theme => css`
          text-decoration: none;
          color: ${hovered ? theme.colors.darkBlue : theme.colors.black};
        `}
      >
        <Image
          src={image}
          css={theme => css`
            box-shadow: ${theme.constants.boxShadow};
            opacity: 0.5;
            filter: grayscale(100%);
            transition: 0.2s ease-in-out;
            width: 100%;
            margin-bottom: 30px;
          `}
        />
        <p
          css={theme => css`
            font-size: ${theme.constants.mediumFontSize}px;
            font-weight: ${theme.constants.demi};
            margin-bottom: 30px;

            @media (max-width: 1000px) {
              min-height: 130px;
              text-align: center;
            }

            @media (max-width: 429px) {
              min-height: auto;
            }
          `}
        >
          {title}
        </p>
        <p
          css={theme => css`
            font-size: ${theme.constants.infoDescriptionTextSize}px;
            font-weight: ${theme.constants.extraLight};
            color: ${theme.colors.black};
            margin-bottom: 30px;
          `}
        >
          {description}
        </p>
      </Link>
      <LinkButton
        arrowVariant={hovered ? "darkblue" : "blue"}
        pure
        css={css`
          width: 260px;

          @media (max-width: 1000px) {
            width: 90%;
          }
        `}
        to={`/projects/${to}`}
      >
        see full project
      </LinkButton>
    </div>
  )
}

export default ProjectBox
